import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import PortableText from '../portableText';

const BlogPostMain = ({ content }) => {
  return (
    <StyledBlogMain
      as="section"
      style={{ maxWidth: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <PortableText blocks={content} />
    </StyledBlogMain>
  );
};

const StyledBlogMain = styled(Container)`
  margin-top: 4.875rem;
  margin-bottom: 0;
`;

export default BlogPostMain;
