import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';

import { localizeDate } from '../lib/helpers';
import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BlogPostHeader from '../components/BlogPostTemplate/BlogPostHeader';
import BlogPostMain from '../components/BlogPostTemplate/BlogPostMain';
import ShareButtons from '../components/ShareButtons';
import PageFooter from '../components/PageFooter';
import RelatedContentBlock from '../components/RelatedContentBlock';

const BlogPost = ({ data, pageContext, path, location }) => {
  // console.log(pageContext);
  // console.log(location);
  const { title, publishedAt, headerImage, _rawContent, related } = data.post;
  const localePublishingDate = localizeDate(publishedAt, pageContext.locale, 'long');
  const seoDescription = _rawContent[0].children[0].text;

  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO
        title={title}
        description={seoDescription}
        path={path}
        pageType="article"
        image={headerImage ? headerImage.asset.fluid : ''}
      />
      <article>
        <BlogPostHeader
          title={title}
          publishedAt={localePublishingDate}
          fluidImg={headerImage ? headerImage.asset.fluid : ''}
          pathname={location.pathname}
          parentName={data.blogPage.title}
        />

        <BlogPostMain content={_rawContent} />

        <PageFooter>
          <Container className="py-4 d-flex justify-content-center flex-wrap">
            <ShareButtons url={location.href} title={title} lang={pageContext.locale} />
          </Container>
        </PageFooter>

        <PageFooter>
          {related && related.length > 0 ? (
            <RelatedContentBlock
              references={related}
              lang={pageContext.locale}
              location={location.origin}
            />
          ) : (
            ''
          )}
        </PageFooter>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    blogPage: sanityPage(id: { eq: "-d01d65c9-52f9-5731-8fc7-29b43fd70810" }) {
      title {
        _type
        de
        en
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      title {
        _type
        de
        en
      }
      headerImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      publishedAt
      _rawContent(resolveReferences: { maxDepth: 5 })
      related {
        ... on SanityEvent {
          id
          slug {
            current
          }
          title {
            _type
            de
            en
          }
          location
          eventDate
          _rawShortDescription
        }
        ... on SanityRecording {
          id
          slug {
            current
          }
          title {
            _type
            de
            en
          }
          imageOnly {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          publishedAt
        }
      }
    }
  }
`;

export default localize(BlogPost);
