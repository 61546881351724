import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import Breadcrumb from '../Breadcrumb';

const BlogPostHeader = ({ withImage, fluidImg, title, publishedAt, pathname, parentName }) => {
  if (fluidImg) {
    return (
      <StyledBackgroundImage fluid={fluidImg}>
        <StyledBlogHeaderContainer>
          <Breadcrumb pathname={pathname} parentName={parentName} />
          <StyledBackgroundSectionTitle>{title}</StyledBackgroundSectionTitle>
          <StyledBlogMeta>{publishedAt}</StyledBlogMeta>
        </StyledBlogHeaderContainer>
      </StyledBackgroundImage>
    );
  } else {
    return (
      <StyledBackgroundColor>
        <StyledBlogHeaderContainer>
          <StyledBackgroundSectionTitle>{title}</StyledBackgroundSectionTitle>
          <StyledBlogMeta>{publishedAt}</StyledBlogMeta>
        </StyledBlogHeaderContainer>
      </StyledBackgroundColor>
    );
  }
};

const StyledBackgroundColor = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.black};
  display: flex;

  justify-content: center;
  align-items: center;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.6);
`;

const StyledBlogHeaderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledBackgroundSectionTitle = styled.h1`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`;

const StyledBlogMeta = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.05rem;

  & strong {
    font-weight: 500;
  }
`;

export default BlogPostHeader;
